export default [
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
  {
    key: 'phoneNumber',
    label: 'field.phoneNumber',
    type: 'tel',
    operator: '%',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'asyn-single-selection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    rules: 'required',
    type: 'radio',
    cast: 'boolean',
    options: [
      { text: 'field.active', value: '1' },
      { text: 'field.inactive', value: '0' },
    ],
    lg: 'auto',
  },
]
